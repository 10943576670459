
.PubList {
	text-align: left;
	padding: 15px 0px;
}


.SectionWithPic {
	display: flex;
	flex-direction: row;
	
	text-align: left;
}

.SectionWithPic > img {
	max-width: 800px;
	padding: 15px;
}

.PubSectionHeader {
	margin-top: 20px;
}

.PubListing {
	margin: 12px 0px;
}