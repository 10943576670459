.App {

}

.MainBody {
	font-size: 1.2em;
	margin: auto;
	
	max-width: 1200px;
}



.DescriptiveText {
	padding: 12px;
}

.Copyright {
	font-size: 0.65em;
	text-align: center;
}

