.TopSection {
	display: flex;
	justify-content: flex-start;
	
	flex-wrap: wrap;
}

.TopSection > div {
	padding: 0 15px;
}



.FrontPageText {
	width: 100%;
	max-width: 1200px;
	
	text-align: left;
}

.FrontPageText > p {
	margin-top: 13px;
}

.ContactInfo {
	font-size: 1em;
}