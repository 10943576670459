.TopBar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	
	padding: 5px;
	max-width: 1200px;
	margin: 10px auto;
}


.Title {
	font-family: 'Nanum Gothic', sans-serif;
	font-weight: 700;
	font-size:2.5em;
	cursor: pointer;
	
	margin: 5px;
	
	color: ##\2e2e2e;
	
}

.NavBar {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.NavBar > p {
	font-size: 1.3em;
	margin: 5px;
	padding: 10px;
	cursor: pointer;
}