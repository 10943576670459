@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&family=Nunito+Sans:ital@0;1&display=swap');

body {
  margin: 0;
  font-family: 'Nunito Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	
	background-color: #fafafa;
	
	color: #433c3c;
}



a:link {
	color: dodgerblue;
	text-decoration: none;
}

a:visited {
	color: dodgerblue;
}

h2 {
	font-size: 1.2em;
	margin: 12px 0px;
}

img {
	border-radius: 10px;
}


.SplashImage {
	padding: 10px;
}
.SplashImage > img {
	max-width: 775px;
	width: 97%;
}

.Caption {
	font-size: 0.7em;
}
